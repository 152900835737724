@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --body-background: #c5c9d4ce;
  --body--color: #000;
  --link--color: navy;
  --card-header-color-rgb: 33, 37, 41;
  --card-background: #fff;
  --card-border: #f2f3f8;
  --card-color: #464457;    
  --nav-background: #20336b;
  --btn-background-color: #20336b;
  --btn-background-color-hover: #00baf2;
  --btn-color: #fff;
  --btn-color-hover: #20336b;
}

[data-theme="dark"] {
  --body-background: #000;
  --text-primary: #fff;
  --card-background: #222327;
  --card-border: #ccc;
  --card-color: #fff;
  --card-header-color-rgb: #dadce0;
  --nav-background: #464446;
  --btn-background-color: #00baf2;
  --btn-background-color-hover: #20336b;
  --btn-color-hover: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} 

input[type="number"] {
  -moz-appearance: textfield;
}


/* LoaderCss */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000a8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  backdrop-filter: blur(5px);
  z-index: 9;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 4px solid;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid;
  border-color: transparent transparent #20336b #20336b;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: 70px;
  height: 70px;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.card-footer {
  padding: 8px 16px 16px 16px;
  text-align: center;
  background-color: var(--card-background);
  border-top: solid 2px var(--card-border);
  font-size: 16px;
  color: #464457;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0px 10px 0px 10px;
}

.card-header > span {
  font-size: 16px;
  padding: 6px;
  display: inline-block;
  font-weight: bold;
}

.card-header {
  font-size: 16px;
  padding: 6px;
  display: inline-block;
  font-weight: bold;
}

label {
  font-weight: bold;
  /* display: flex;
  justify-content: flex-end; */
}

.card {
  margin: 10px 10px 10px 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid var(--card-border);
  background-color: var(--card-background);
  color: var(--card-color);
}

.card-header {
  background-color: var(--card-header-color-rgb);
  color: #333;
}

.selectStartIcon {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.selectEndIcon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.ValidationErrorMsg {
  width: 100%;
  margin-top: -1rem;
  font-size: 0.875em;
  color: #dc3545;
}

.css-13cymwt-control {
  border-radius: 0px !important;
}

.borderRed .css-13cymwt-control {
  border-color: #dc3545;
}

.offcanvas.offcanvas-start.show {
  z-index: 1111111;
}

.offcanvas.offcanvas-start {
  width: 50%;
}

.offcanvas-header {
  margin: 2px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #20336b;
  margin-bottom: 2px;
}

.offcanvas-title {
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  /* Default font size for p tag */
  font-family: "Inter", sans-serif;
  margin: 0;
}

.offcanvas-header .btn-close {
  opacity: 1;
  background-image: url("./assets/icons8-close-94.png");
  background-size: 25px;
  /* Set the size of the background image */
  background-repeat: no-repeat;
}

.offcanvas-body {
  border: 1px solid black;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 2px;
}

.offcanvas {
  background-color: rgba(255, 255, 255, 0);
  border: none !important;
}

.LoaderWithLogo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.LoaderWithLogo img {
  width: 400px;
  /* Adjust the size as needed */
  height: auto;
}

.deleteIcon:hover {
  color: red;
}

.editIcon:hover {
  color: green;
}

.iconsFonts {
  font-size: 28px !important;
  vertical-align: middle !important;
  cursor: pointer;
}

/* .ck-editor__editable_inline {
  min-height: 300px;
} */

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  padding-top: 0px;
  padding: 0px 25px;
}

.input-group .form-control {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.btn-close-white {
  filter: none;
}

.modal-header .btn-close {
  opacity: 1;
  background-image: url("./assets/icons8-close-94.png");
  background-size: 25px;
  /* Set the size of the background image */
  background-repeat: no-repeat;
}

.modal-header {
  /* box-shadow: 0px 20px 20px -20px rgba(0,0,0,0.45); */
  margin: 2px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #20336b;
  margin-bottom: 2px;
}

.modal-body {
  border: 1px solid black;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 2px;
}

.modal-title {
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  /* Default font size for p tag */
  font-family: "Inter", sans-serif;
  margin: 0;
}

.modal-content {
  background-color: #ecf0f500;
  border: none !important;
}

.btn-tapti {
  font-size: 18px;
  padding: 0px 16px 0px 16px;
  color: #303030;
  font-weight: 550;
  font-family: "Inter", sans-serif;
  background-color: #20336b;
  color: #e4e9f7;
  box-shadow: 1px 4px 5px 0px rgba(48, 48, 48, 0.45);
  border-radius: 24px;
  border: 1px solid #303030;
  height: 44px;
}

.btn-tapti:hover {
  color: black;
  background-color: rgb(130, 194, 251);
  border: 1px solid black;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 1px 4px 5px 0px #20336b;
  font-weight: 700;
}

.hastags {
  background-color: silver;
  color: black;
  padding: 5px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 4px;
}

.borderOFSendSMS {
  border: 1px solid #20336b;
  padding: 10px;
  border-radius: 8px;
}

.table thead tr th {
  background-color: #20336b;
  color: white;
  font-weight: normal;
}
.table td,
.table th {
  padding: 0.75rem;
}

/* select */

.css-1nmdiq5-menu {
  z-index: 999999 !important;
}

.dropdown-item {
  padding: 0px;
  margin: 0px;
  color: white !important;
}

/* Default font size for the summary panel */
.ag-paging-row-summary-panel {
  font-size: 15px;
}

/* Media queries for different screen sizes */

/* For laptops and desktops */
@media (min-width: 1024px) {
  .ag-paging-row-summary-panel {
    font-size: 15px; /* Adjust font size for larger screens */
  }
}

/* For tablets */
@media (max-width: 1023px) and (min-width: 768px) {
  .ag-paging-row-summary-panel {
    font-size: 15px; /* Adjust font size for tablets */
  }
}

/* For mobile devices */
@media (max-width: 767px) {
  .ag-paging-row-summary-panel {
    font-size: 10px;
    /* display: none;Adjust font size for mobile */
  }
  .ag-picker-field .ag-label {
    font-size: 10px;
  }
}

@media (max-width: 470px) {
  .ag-paging-row-summary-panel {
    font-size: 10px;
    display: none; /* Adjust font size for mobile */
  }
  .ag-picker-field .ag-label {
    font-size: 10px;
    display: none;
  }
}

.ag-body-horizontal-scroll-viewport {
  scrollbar-width: thin;
}

.ag-body-vertical-scroll-viewport {
  scrollbar-width: thin;
}

.ag-horizontal-right-spacer {
  scrollbar-width: thin;
}

.nav-underline .nav-link {
  border: 2px solid #20336b;
  color: #20336b;
  font-weight: 700;
  margin: 6px;
  padding: 8px;
}

.nav-underline .nav-link:hover {
  border: 2px solid #0d6efd;
  background-color: #0d6efd;
  color: white;
  font-weight: 700;
  margin: 6px;
  padding: 8px;
}

.nav-underline .nav-link.active {
  background-color: #20336b;
  border: 2px solid #20336b;
  color: #fff;
  font-weight: 700;
  margin: 6px;
  padding: 8px;
}

.nav-underline {
  gap: 0;
}

/* SubClaimTable */

.SubClaimTable thead tr th {
  /* background-color: red; */
  font-size: 14px;
  padding: 5px;
}

.SubClaimTable td {
  padding: 3px;
  /* background-color: #0d6efd; */
  /* font-size: 18px; */
}

.SubClaimTable td .form-control {
  font-size: 14px;
}

.SubClaimTable td .form-select {
  font-size: 14px;
}

/* .policy-old {
  background-color: rgb(235, 195, 195);
}

.policy-current {
  background-color: rgb(179, 240, 179);
}

.policy-new {
  background-color: rgb(179, 189, 233);
} */

.policy-card {
  border: none;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #1976d2;
  margin: 20px 0;
}

.card-header-custom {
  background-color: #1976d2;
  color: white;
  padding: 15px;
  font-size: 1.2em;
  border-bottom: 3px solid #1565c0;
  border-radius: 10px 10px 0 0;
}

.policy-section {
  display: flex;
  flex-direction: column;
}

.policy-scroll {
  overflow-y: auto;
  max-height: 600px;
}

.policy-table {
  margin-bottom: 20px;
}

.policy-table-title {
  background-color: #f0f0f0;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  border: 1px solid black;
  text-align: center;
  font-size: 20px;
}

.policy-table-content {
  overflow-x: auto;
}

.policy-table-content table {
  width: 100%;
  border-collapse: collapse;
}

.policy-table-content th {
  border: 1px solid #0f0f0f;
  padding: 8px;
  text-align: left;
  background-color: #1976d2; /* Background color for table headers */
  color: white;
  font-weight: 500;
}

.policy-table-content td {
  border: 1px solid #0f0f0f;
  padding: 8px;
  text-align: left;
}

.table-centered th,
.table-centered td {
  text-align: center;
  vertical-align: middle; /* This aligns the content vertically in the middle */
}

.table-centered {
  margin: 0 auto; /* Center the table itself if needed */
}

.policy-new {
  background-color: #c3e8c8; /* Light green */
}

.policy-current {
  background-color: #add8e6; /* Light blue */
}

.policy-old {
  background-color: #f9a8a8; /* Light red */
}

@media (max-width: 768px) {
  .policy-table-content table {
    min-width: 100%;
  }
}

/* Assuming you have a CSS or SCSS file linked */

.cardbody {
  border: 1px solid rgb(201, 201, 201);
  border-radius: 8px;
}

.claim-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px; 
}

.main-claim-table{
  border: 1px solid black;
}

.claim-card {
  flex: 1 1 300px; 
  margin-bottom: 20px; 
  border: 1px solid #ccc; 
  border-radius: 8px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s; 
}

.sub-claim-cards {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto; 
  margin-bottom: 20px;
}

.sub-claim-card {
  flex: 0 0 auto; 
  width: 300px; 
  margin-right: 10px; 
  background-color: #fff; 
  border-radius: 8px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s; 
}

.sub-claim-card:hover {
  transform: translateY(-5px); 
}

.sub-claim-card:hover {
  transform: translateY(-5px);
}

.sub-claim-card .list-group-item {
  border: none; 
  padding: 0.75rem 1rem; 
}

.sub-claim-card .list-group-item:not(:last-child) {
  border-bottom: 1px solid #e9ecef;
}


.ag-row:hover {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
  z-index: 2;
}


.ag-root .ag-cell {
  user-select: text !important;
}


.SendSMSButton{
  font-size: 16px;
}


@media (max-width: 1200PX) {
  .SendSMSButton{
    font-size: 14px;
  }
}


.SendSMSButton .dropdown-toggle{
  width: 100%;
}


@media (max-width: 1000px) {
  .PolicyFilterRow .col-sm-12{
    margin-top: 5px;
    margin-bottom: 5px;
  }
}



@media (max-width: 450px) {
  .policyTopButton{
    font-size: 12px;
    height: 30px;
  }
}



 .dropdown-item {
  color: #fff !important;
  display: block;
  padding: 8px 12px !important;
}


/* .navbar .typeHade .dropdown-menu {
  overflow: hidden !important;
  background-color: #00baf2;
} */

 .dropdown-menu {
  overflow: hidden !important;
  background-color: #fbfbfb;
  color: white !important;
}

 .dropdown-item:hover {
  background-color: #0d6efd !important;
  border: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255) !important;
}

.dropdown-item>a{
  padding: 0px !important;
}


.input-group .RemoveBorderRadiusTextBox {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* CenteredImage.css */
.LogoComponent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  overflow: hidden;
}

.LogoComponent-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}



.kycTabs {
  border-bottom: 2px solid #007bff; 
  background-color: #f8f9fa !important; 
  border-radius: 5px;
}


.kycTabs .nav-link {
  color: #007bff !important; 
  font-weight: bold !important;
  border: none!important;
  padding: 10px 20px !important; 
  border-radius: 5px 5px 0 0 !important; 
}

/* Hover effect for Tab links */
.kycTabs .nav-link:hover {
  color: #0056b3 !important; 
  background-color: #eceafd !important; 
  text-decoration: none !important; 
}

/* Active Tab styles */
.kycTabs .nav-link.active {
  color: #000000 !important; 
  background-color: #ffffff !important; 
  border: 2px solid #007bff !important; 
  border-bottom: none !important; 
  border-radius: 5px 5px 0 0 !important; 
}

