/* CustomCheckbox.css */
.checkbox-container {
    display: inline-block;
    cursor: pointer;
    margin: 0.5rem;
  }
  
  .checkbox-wrapper {
    display: flex;
    align-items: center;

  }
  
  .checkbox-input {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  
  .checkbox-input:checked + .checkbox-tile {
    border-color: var(--icon-color);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #2260ff;
  }
  
  /* .checkbox-input:checked + .checkbox-tile::before {
    transform: scale(1);
    opacity: 1;
    background-color: #2260ff;
    border-color: #2260ff;
  } */
  
  .checkbox-input:checked + .checkbox-tile .checkbox-icon,
  .checkbox-input:checked + .checkbox-tile .checkbox-label {
    color: #2260ff;
  }
  
  .checkbox-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.15s ease;
    position: relative;
    cursor: pointer;
  }
  
  .checkbox-tile::before {
    content: "";
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  
  /* .checkbox-tile:hover {
    border-color: #2260ff !important;
    color: #2260ff !important;
  } */
  
  /* .checkbox-tile:hover::before {
    transform: scale(1);
    opacity: 1;
  } */
  
  .checkbox-icon {
    transition: 0.375s ease;
    color: #494949;
    font-size: 3rem; /* Adjust icon size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0px 34px 5px 34px;
  }
  
  .checkbox-label {
    color: #707070;
    transition: 0.375s ease;
    text-align: center;
  }
  
  /* .checkbox-input:focus{
    border: 2px solid black;
    box-shadow: 10px 10px 10px black;
  } */
 
  .checkbox-wrapper:focus-within .checkbox-tile {
    box-shadow: 0px 0px 8px 4px #86b7fe;
    border-color: #000; 
  }
  /* Responsive Design */
  
  @media (max-width: 992px) {
    .checkbox-tile {
      width: 6rem; /* Adjust width for medium screens */
      /* min-height: 6rem; Adjust height for medium screens */
    }
  
    .checkbox-icon {
      font-size: 2.5rem; /* Adjust icon size for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .checkbox-tile {
      width: 5rem; /* Adjust width for smaller screens */
      /* min-height: 5rem; Adjust height for smaller screens */
    }
  
    .checkbox-icon {
      font-size: 2rem; /* Adjust icon size for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .checkbox-tile {
      width: 5rem; /* Adjust width for very small screens */
      /* min-height: 4rem; Adjust height for very small screens */
    }
  
    .checkbox-icon {
      font-size: 2rem; /* Adjust icon size for very small screens */
    }
  }



  @media (max-width: 1370px) and (min-width: 990px) {
    .checkbox-tile {
      width: 6rem; /* Adjust width for medium screens */
      /* min-height: 6rem; Adjust height for medium screens */
    }
  
    .checkbox-icon {
      font-size: 2.5rem; /* Adjust icon size for medium screens */
    }
  }