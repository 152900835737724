/* Metric Cards */
.metric-cards {
  display: flex;
}

.metric-card {
  background: linear-gradient(135deg, #0d6efd, #20336b);
  color: #ffffff;
  flex: 1;
  min-width: 250px;
  padding: 20px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.metric-card:hover {
  transform: translateY(-5px);
}

.metric-card .icon {
  font-size: 40px;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.6;
}

.metric-card h5 {
  font-size: 18px;
  margin-bottom: 15px;
  text-decoration: underline;
}

.metric-card h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.metric-card .change {
  font-size: 14px;
}

@media screen and (max-width: 991px) {
  .metric-card {
    margin: 7px 0px;
  }
  .MainRow {
    margin: 7px 0px;
  }
}

/* style for heading Start*/
.headerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between text and button */
  background-color: #f8f9fa;
  color: #343a40;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  font-size: 28px;
  letter-spacing: 1px;
  box-shadow: 0px 3px 11px #c1c7d1;
}

.iconStyle {
  font-size: 30px;
  margin-right: 8px;
  color: #20336b;
}

.headerTextStyle {
  flex: 1; /* This allows the header text to take up available space */
  text-align: center; /* Centers the header text */
  text-transform: uppercase;
  font-weight: 800;
  font-size: 19px !important;
  letter-spacing: 1px;
}

.mb-20px {
  margin-bottom: 20px;
}

/* style for heading End*/

.MainRow {
  margin: 20px 0px;
}

.Card-Body {
  border: 1px solid rgb(224, 222, 222);
}

/* Style For table start */

.tableCard {
  margin-top: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden; /* Ensures rounded corners are visible */
}

.tableCard .card-header {
  background: linear-gradient(357deg, #0d6efd, #20336b);
  color: #ffffff;
  padding: 15px;
  font-size: 20px;
  border-bottom: 2px solid #0056b3; /* Subtle border for header */
  font-weight: 500;
}

.tableCard .table {
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0;
}

.tableCard .table thead th {
  position: sticky;
  top: 0;
  background-color: #003d7d;
  color: #ffffff;
  padding: 12px;
  z-index: 1;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  font-weight: 500;
}

.table th,
.table td {
  text-align: center;
  padding: 12px;
  font-size: 14px;
  color: #333; /* Darker text color for better readability */
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9; /* Light grey for odd rows */
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #ffffff; /* White for even rows */
}

.table-hover tbody tr:hover {
  background-color: #e2e2e2; /* Light grey for hover effect */
  cursor: pointer;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #d1d1d1; /* Lighter border for a softer look */
}

.table-scroll-wrapper {
  max-height: 400px;
  overflow-y: auto;
}

.card-body {
  padding: 0;
}

.tableCard .no-data {
  text-align: center;
  padding: 20px;
  color: #888; /* Grey color for no data text */
  font-style: italic;
}

/* Style For table End  */

::-webkit-scrollbar {
  display: none;
}



